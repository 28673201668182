import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink } from 'react-router-dom';

import classes from './bottom-navigation.module.css';

function BottomNavigation() {
  return (
    <Navbar fixed="bottom" bg="dark">
      <Container>
        <Navbar.Collapse  className="justify-content-end">
          <Nav>
            <NavLink className={classes['bottom-menu']} to="/terms">Terms & Conditions</NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default BottomNavigation;