import AuthContext from "../../store/auth-context";
import { useContext } from "react";

const Statement = () => {
  const atx = useContext(AuthContext);
  console.log(atx.token);
  return <h1>Statement</h1>;

};

export default Statement;
