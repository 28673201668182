import { useContext } from "react";
import { NavLink } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";


import AuthContext from "../../store/auth-context";
import NotificationContext from "../../store/notifications-context";

const Profile = () => {
  const atx = useContext(AuthContext);

  return <>
  <h2>My Profile</h2>
  <Container>
    <Row>
        <Col sm={9}>
            <Row>
            {atx.userInfo.message.synesis_user_meta.title === undefined && 'Mr'}
            {atx.userInfo.message.synesis_user_meta.title !== undefined && atx.userInfo.message.synesis_user_meta.title}
            {' '}{atx.userInfo.message.given_name} {' '} {atx.userInfo.message.family_name}
            </Row>
            <Row>
                {atx.userInfo.message.email} {' '}
                {atx.userInfo.message.email_verified === true && '(Verified)'}
                {atx.userInfo.message.email_verified === false && '(Not Verified)'}
                
            </Row>
        </Col>
        <Col sm={3}>
            <button type="Submit" className="btn btn-primary float-end">
            Login
            </button>
        </Col>
    </Row>
    </Container>
  </>;

};

export default Profile;
