import { useContext } from "react";

import ResetNotifications from "../../components/reset-notifications";
import NotificationContext from "../../store/notifications-context";

const TermsAndConditions = () => {
  const ctx = useContext(NotificationContext);
  ResetNotifications(ctx);
  return <h1>Terms and Conditions</h1>;
};

export default TermsAndConditions;
