import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AuthContext = React.createContext({
    token: '',
    isLoggedIn: false,
    login: (token) => {},
    logout: () => {},
    userInfo: {},
    setuserInfo: () => {}
})

export const AuthContextProvider = (props) => {
    const [token, setToken] = useState();
    const [userInfo, setUserInfo] = useState({});


    const storedLoggedInInfo = localStorage.getItem('isLoggedIn');
    const storedUserInInfo = localStorage.getItem('userData');
    const storedToken = localStorage.getItem('token');

    useEffect(()=>{
        const userData = JSON.parse(storedUserInInfo);
        console.log(userData);
        setUserInfo(userData);
        // conditional here
        setToken(storedToken);
    }, []);



    const userIsLoggedIn = !!token;

    const loginHandler = (token) => {
        setToken(token);
        localStorage.setItem('token', token);
    }

    const logoutHandler = () => {
        setToken(null);
        localStorage.removeItem('token');
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('userData');
    }

    const userInfoHandler = (data) => {
        setUserInfo(data);
        localStorage.setItem('isLoggedIn', '1');
        localStorage.setItem('userData', JSON.stringify(data));
    }

    const contextValue = {
        token: token,
        isLoggedIn: userIsLoggedIn,
        login: loginHandler,
        logout: logoutHandler,
        userInfo: userInfo,
        setuserInfo: userInfoHandler
    };

    return (<AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>);
}

export default AuthContext;