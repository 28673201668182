import AuthContext from "../../store/auth-context";
import React, { useState, useEffect, useContext } from "react";
import NotificationContext from "../../store/notifications-context";

const UserInfo = () => {
  const [areDetailsLoaded, setAreDetailsLoaded] = useState(false);
  const [pageData, setPageData] = useState({
    "message": {
      "sub": "",
      "given_name": "",
      "family_name": "",
      "nickname": "",
      "name": "",
      "picture": "",
      "updated_at": "",
      "email": "",
      "email_verified": false,
      "https://test.syncap.co.ke/user_meta": {
        "title": "Mr."
      }
    }
  });
  const atx = useContext(AuthContext);
  const ctx = useContext(NotificationContext);
  console.log(atx.token);
  const token = atx.token;

  useEffect(() => {
    fetch(`http://${process.env.REACT_APP_SERVER_IP}:${process.env.REACT_APP_SERVER_PORT}/users/get_userinfo`, {
      method: "POST",
      body: JSON.stringify({}),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + atx.token,
      },
    }).then((response) => {
      if (response.ok) {
        response
          .json()
          .then((data) => {
            console.log(data);
            console.log(data.message.given_name)
            atx.setuserInfo(data);
            setPageData(data);
          })
          .catch((err) => {
            console.log(err);
            ctx.setAlertVariant("danger");
            ctx.setAlertHeading("Could not submit your information1");
            ctx.setAlertMessage("Correct the errors, then try again.");
            ctx.setShowAlert(true);
          });
      } else {
        ctx.setAlertVariant("danger");
        ctx.setAlertHeading("Could not submit your information2");
        ctx.setAlertMessage("Correct the errors, then try again.");
        ctx.setShowAlert(true);
      }
    }).then((data)=>{
      setAreDetailsLoaded(true);
    });
    
  }, []);

  return(
  <>
  <h1>Welcome Page</h1>
 {areDetailsLoaded === true && <div className="App">{pageData.message.given_name} {pageData.message.family_name}</div>}
 {!areDetailsLoaded === true && <div className="App">Loading...</div>}
  </>
  )
};

export default UserInfo;
