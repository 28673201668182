import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import { Link } from "react-router-dom";

import classes from "./main-navigation.module.css";

import { useContext } from "react";

import AuthContext from "../store/auth-context";
import NavDropdown from "react-bootstrap/NavDropdown";

function MainNavigation() {
  const atx = useContext(AuthContext);
  const isLoggedIn = atx.isLoggedIn;

  const logoutHandler = () => {
    atx.logout();
  };

  return (
    <Navbar bg="secondary" variant="dark" expand="lg">
      {!isLoggedIn && (
        <Container>
          <Navbar.Brand as={Link} to="/login">Masaru Trader</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            className="justify-content-end"
            id="basic-navbar-nav"
          >
            <Nav>
              <Nav.Item>
                <Nav.Link
                  className="text-white justify-content-end"
                  as={Link}
                  to="/login"
                >
                  Sign In
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="text-white justify-content-end"
                  as={Link}
                  to="/registration"
                >
                  Create Account
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      )}
      {isLoggedIn && (
        <Container>
          <Navbar.Brand as={Link} to="/welcome">
            Masaru Trader
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            className="justify-content-end"
            id="basic-navbar-nav"
          >
            <Nav>
              <Nav.Item>
                <Nav.Link
                  className="text-white justify-content-end"
                  as={Link}
                  to="/products"
                >
                  Our Products
                </Nav.Link>
              </Nav.Item>
              <NavDropdown
                title={<span className="text-white">My Account</span>}
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item as={Link} to="/profile">
                  My Profile
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/products">
                  My Products
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/statement">
                  My Statement
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={logoutHandler}>
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      )}
    </Navbar>
  );
}

export default MainNavigation;
