import { useContext, useState } from "react";
import useInput from "../../hooks/use-input";

import ResetNotifications from "../../components/reset-notifications";
import NotificationContext from "../../store/notifications-context";
import AuthContext from "../../store/auth-context";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import { NavLink } from "react-router-dom";

import { useNavigate } from "react-router-dom";

import Stepper from "react-stepper-horizontal";

import "../../App.css";

const smallLetters = "abcdefghijklmnopqrstuvwxyz";
const capitalLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
const numbers = "1234567890";
const specialCharacters = "[$&+,:;=?@#|'<>.^*()%!-]\"";

const isNotEmpty = (value) => value.trim() !== "";
const isEmail = (value) => {
  return value.includes("@");
};
const isEmailAleadyTaken = (value) => {
  if (!value.includes("@")) {
    return false;
  }
  fetch(
    `${process.env.REACT_APP_SERVER_PROTOCOL}${process.env.REACT_APP_SERVER_IP}:${process.env.REACT_APP_SERVER_PORT}/users/get_user_by_email`,
    {
      method: "POST",
      mode: 'no-cors',
      body: JSON.stringify({
        email: value,
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Request-Headers": "*",
      },
    }
  )
    .then((response) => {
      if (response.ok) {
        console.log("ok - first response");
        response.json().then((data) => {
          console.log(data);
          console.log(data.message.length);
          if (data && data.message && data.message.length > 0) {
            console.log(false);
            return false;
          } else {
            console.log(true);
            return true;
          }
        });
      } else {
        response.json().then((value) => {
          return true;
        });
      }
    })
    .catch((err) => {
      return true;
    });
};

const RegistrationStepper = () => {
  const ctx = useContext(NotificationContext);
  const atx = useContext(AuthContext);
  const [form_step, setFormStep] = useState(0);
  const [oob_code, setOobCode] = useState("");
  const [mfa_token, setMfaToken] = useState("");
  const [access_token, setAccessToken] = useState("");
  const [emailHasErrorState, setemailHasErrorState] = useState(false);
  const [user_info, setUserInfo] = useState("");
  const [authenticator, setAuthenticator] = useState("");

  const navigate = useNavigate();
  ResetNotifications(ctx);

  let {
    value: emailValue,
    isValid: emailIsValid,
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: resetEmail,
    submitto: submitEmail,
  } = useInput(isEmail);

  const {
    value: passwordValue,
    isValid: passwordIsValid,
    hasError: passwordHasError,
    valueChangeHandler: passwordChangeHandler,
    inputBlurHandler: passwordBlurHandler,
    reset: resetpassword,
    submitto: submitPassword,
  } = useInput(isNotEmpty);

  const {
    value: countryValue,
    isValid: countryIsValid,
    hasError: countryHasError,
    valueChangeHandler: countryChangeHandler,
    inputBlurHandler: countryBlurHandler,
    reset: resetCountry,
    submitto: submitCountry,
  } = useInput(isNotEmpty);

  const {
    value: phoneNumberValue,
    isValid: phoneNumberIsValid,
    hasError: phoneNumberHasError,
    valueChangeHandler: phoneNumberChangeHandler,
    inputBlurHandler: phoneNumberBlurHandler,
    reset: resetphoneNumber,
    submitto: submitphoneNumber,
  } = useInput(isNotEmpty);

  const {
    value: verificationNumberValue,
    isValid: verificationNumberIsValid,
    hasError: verificationNumberHasError,
    valueChangeHandler: verificationNumberChangeHandler,
    inputBlurHandler: verificationNumberBlurHandler,
    reset: resetVerificationNumber,
    submitto: submitVerificationNumber,
  } = useInput(isNotEmpty);

  const {
    value: titleValue,
    isValid: titleIsValid,
    hasError: titleHasError,
    valueChangeHandler: titleChangeHandler,
    inputBlurHandler: titleBlurHandler,
    reset: resetTitle,
    submitto: submitTitle,
  } = useInput(isNotEmpty);

  const {
    value: firstNameValue,
    isValid: firstNameIsValid,
    hasError: firstNameHasError,
    valueChangeHandler: firstNameChangeHandler,
    inputBlurHandler: firstNameBlurHandler,
    reset: resetFirstName,
    submitto: submitFirstName,
  } = useInput(isNotEmpty);

  const {
    value: lastNameValue,
    isValid: lastNameIsValid,
    hasError: lastNameHasError,
    valueChangeHandler: lastNameChangeHandler,
    inputBlurHandler: lastNameBlurHandler,
    reset: resetLastName,
    submitto: submitLastName,
  } = useInput(isNotEmpty);

  let formIsValid = false;
  let verificationFormIsValid = false;
  let phoneFormIsValid = false;
  let emailFormIsValid = false;
  let passwordFormIsValid = false;

  if (titleIsValid && firstNameIsValid && lastNameIsValid) {
    formIsValid = true;
  }

  if (emailIsValid) {
    emailFormIsValid = true;
  }

  if (phoneNumberIsValid && countryIsValid) {
    phoneFormIsValid = true;
  }

  if (passwordIsValid) {
    passwordFormIsValid = true;
  }

  if (verificationNumberIsValid) {
    verificationFormIsValid = true;
  }

  const onSubmitHandler = (event) => {
    event.preventDefault();

    if (!formIsValid) {
      ctx.setModalTitle("Could not save names");
      ctx.setModalMessage(
        "Please correct the errors on the form and try again"
      );
      ctx.setShowModal(true);
      submitTitle();
      submitFirstName();
      submitLastName();
      return;
    }

    addStepper();

    // resetTitle();
    // resetFirstName();
    // resetLastName();
  };

  const onPasswordSubmitPhoneHandler = (event) => {
    event.preventDefault();

    const passwordValueArray = passwordValue.split("");

    let hasSmallLetters = false;
    let hasSpecialCharacters = false;
    let hasNumbers = false;
    let hasCapitalLetters = false;
    let hasProperLength = false;

    passwordValueArray.forEach((value) => {
      if(smallLetters.includes(value)){
        hasSmallLetters = true;
      }
      if(specialCharacters.includes(value)){
        hasSpecialCharacters = true;
      }
      if(numbers.includes(value)){
        hasNumbers = true;
      }
      if(capitalLetters.includes(value)){
        hasCapitalLetters = true;
      }
    });

    if (passwordValue.length >= 8){
      hasProperLength = true
    };

    if (!passwordFormIsValid | !hasSmallLetters | !hasNumbers | !hasCapitalLetters | !hasSpecialCharacters | !hasProperLength) {
      ctx.setShowModal(true);
      ctx.setModalTitle("Could not save your password");
      ctx.setModalMessage(
        "Password should be 8 characters or more including a capital letter, a small letter, a number, and a special character in your password."
      );
      submitPassword();
      return;
    }

    addStepper();

    // resetTitle();
    // resetFirstName();
    // resetLastName();
  };

  const onRequestNewOTP = (event) => {
    event.preventDefault();

    if (!phoneFormIsValid || phoneNumberValue.length !== 9) {
      ctx.setShowModal(true);
      ctx.setModalTitle("Could not save your phone number");
      ctx.setModalMessage(
        "Please correct the errors on the form and try again"
      );
      submitphoneNumber();
      submitCountry();
      return;
    }

    ctx.setAlertVariant("info");
    ctx.setAlertHeading("Sending Verification SMS now...");
    ctx.setAlertMessage(
      "A verification code has been sent to +" + countryValue + phoneNumberValue
    );
    ctx.setShowAlert(true);

    fetch(
      `${process.env.REACT_APP_SERVER_PROTOCOL}${process.env.REACT_APP_SERVER_IP}:${process.env.REACT_APP_SERVER_PORT}/users/request_otp`,
      {
        method: "POST",
        body: JSON.stringify({}),
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Request-Headers": "*",
          Authorization: "Bearer " + mfa_token,
        },
      }
    )
      .then((response) => {
        ctx.setShowAlert(false);
        if (response.ok) {
          console.log("ok");

          response.json().then((data) => {
            console.log(data);
            if (data && data.message && data.message.oob_code) {
              setOobCode(data.message.oob_code);
              setMfaToken(data.mfa_token);
              ctx.setModalTitle("Verification Message Sent");
              ctx.setModalMessage(
                "A verification code has been sent to +" +
                  countryValue +
                  phoneNumberValue
              );
              ctx.setShowModal(true);
            } else {
              console.log(data);
              ctx.setModalTitle("Verification Message Not Sent");
              if (data.code && data.code === "invalid_password") {
                ctx.setModalMessage(
                  "Make sure your password is 8 characters. Mix lower case, upper case, numbers and at least 1 special character."
                );
              }
              if (data && data.code === "invalid_signup") {
                ctx.setModalMessage("Have you registered before?");
              }
              ctx.setModalMessage(
                "The account was created but there was a problem sending the verification SMS."
              );
              ctx.setShowModal(true);
            }
          });
        } else {
          console.log("data");
          response.json().then((value) => {
            console.log("The result was not ok");
            console.log(value);
            ctx.setModalTitle("Verification Message Not Sent");
            ctx.setModalMessage(value.data);
            ctx.setShowModal(true);
          });
        }
      })
      .catch((err) => {
        ctx.setShowAlert(false);
        console.log("err");
        console.log(err);
        ctx.setModalTitle("Verification Message Not Sent");
        ctx.setModalMessage(err.message);
        ctx.setShowModal(true);
      });

    // resetTitle();
    // resetFirstName();
    // resetLastName();
  };

  const onSubmitPhoneHandler = (event) => {
    event.preventDefault();

    // if (phoneNumberValue.length > 0 && phoneNumberValue[0] === '0'){
    //   phoneNumberValue = phoneNumberValue.slice(1);
    // }

    if (!phoneFormIsValid || phoneNumberValue.length !== 9) {
      ctx.setShowModal(true);
      ctx.setModalTitle("Could not save your phone number");
      ctx.setModalMessage(
        "Please check the format of the phone number, correct the errors on the form and try again"
      );
      submitphoneNumber();
      submitCountry();
      return;
    }

    ctx.setAlertVariant("info");
    ctx.setAlertHeading("Sending Verification SMS now...");
    ctx.setAlertMessage(
      "A verification code has been sent to +" + countryValue + phoneNumberValue
    );
    ctx.setShowAlert(true);

    fetch(
      `${process.env.REACT_APP_SERVER_PROTOCOL}${process.env.REACT_APP_SERVER_IP}:${process.env.REACT_APP_SERVER_PORT}/users/register`,
      {
        method: "POST",
        body: JSON.stringify({
          email: emailValue,
          password: passwordValue,
          phone_number: countryValue + phoneNumberValue,
          title: titleValue,
          first_name: firstNameValue,
          last_name: lastNameValue,
        }),
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Request-Headers": "*",
        },
      }
    )
      .then((response) => {
        ctx.setShowAlert(false);
        if (response.ok) {
          console.log("ok");
          response.json().then((data) => {
            console.log(data);
            if (data && data.message && data.message.oob_code) {
              setOobCode(data.message.oob_code);
              setMfaToken(data.mfa_token);
              ctx.setModalTitle("Registration Successful");
              ctx.setModalMessage(
                "A verification email has been sent to your email. A verification code has been sent to +" +
                  countryValue +
                  phoneNumberValue
              );
              ctx.setShowModal(true);
              addStepper();
            } else {
              console.log(data);
              ctx.setShowAlert(false);
              ctx.setModalTitle("Registration Unsuccessful");
              if (data.code && data.code === "invalid_password") {
                ctx.setModalMessage(
                  "Make sure your password is 8 characters. Mix lower case, upper case, numbers and at least 1 special character."
                );
              }
              if (data && data.code === "invalid_signup") {
                ctx.setModalMessage("Have you registered before?");
              }
              ctx.setModalMessage("There was a problem creating the account.");
              ctx.setShowModal(true);
            }
          });
        } else {
          console.log("data");
          response.json().then((value) => {
            console.log("The result was not ok");
            console.log(value);
            ctx.setModalTitle("Registration Unsuccessful");
            ctx.setModalMessage(value.data);
            ctx.setShowModal(true);
            ctx.setShowAlert(false);
          });
        }
      })
      .catch((err) => {
        ctx.setShowAlert(false);
        console.log("err");
        console.log(err);
        ctx.setModalTitle("Something went wrong");
        ctx.setModalMessage(err.data);
        ctx.setShowModal(true);
      });

    // resetTitle();
    // resetFirstName();
    // resetLastName();
  };

  const onSubmitEmailHandler = (event) => {
    event.preventDefault();

    if (!emailValue.includes("@") || emailValue.length < 5) {
      emailIsValid = false;
      emailFormIsValid = false;
      setemailHasErrorState(true);
      ctx.setModalTitle("Could not save your email");
      ctx.setModalMessage("Kindly use a valid email address");
      ctx.setShowModal(true);
    }

    fetch(
      `${process.env.REACT_APP_SERVER_PROTOCOL}${process.env.REACT_APP_SERVER_IP}:${process.env.REACT_APP_SERVER_PORT}/users/get_user_by_email`,
      {
        method: "POST",
        body: JSON.stringify({
          email: emailValue,
        }),
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Request-Headers": "*",
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          console.log("ok - first response");
          response.json().then((data) => {
            console.log(data);
            console.log(data.message.length);
            if (data && data.message && data.message.length > 0) {
              console.log(false);
              emailIsValid = false;
              emailFormIsValid = false;
              setemailHasErrorState(true);
              ctx.setModalTitle("Email Already Exists");
              ctx.setModalMessage("Email is already in use by another account. Please choose another email.");
              ctx.setShowModal(true);
            } else {
              console.log(true);
              emailIsValid = true;
              emailFormIsValid = true;
              setemailHasErrorState(false);
              addStepper();
            }
          });
        } else {
          response.json().then((value) => {
            emailIsValid = false;
            emailFormIsValid = false;
            setemailHasErrorState(true);
          });
        }
      })
      .catch((err) => {
        emailIsValid = false;
        emailFormIsValid = false;
        ctx.setModalTitle("Network Error");
        ctx.setModalMessage("Check your internet connection.");
        ctx.setShowModal(true);
      });
  };

  const onSubmitVerificationHandler = (event) => {
    event.preventDefault();

    if (!verificationFormIsValid) {
      ctx.setModalTitle("Security Code not submitted");
      ctx.setModalMessage(
        "Please check your phone for the SMS, correct the errors on the form and try again"
      );
      ctx.setShowModal(true);
      submitVerificationNumber();
      return;
    }

    fetch(
      `${process.env.REACT_APP_SERVER_PROTOCOL}${process.env.REACT_APP_SERVER_IP}:${process.env.REACT_APP_SERVER_PORT}/users/verify_otp`,
      {
        method: "POST",
        body: JSON.stringify({
          oob_code: oob_code,
          binding_code: verificationNumberValue,
        }),
        headers: {
          Authorization: "Bearer " + mfa_token,
          "Content-Type": "application/json",
          "Access-Control-Request-Headers": "*",
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          response
            .json()
            .then((data) => {
              if (data && data.message && data.message.access_token) {
                console.log(data);
                setAccessToken(data.message.access_token);
                setUserInfo(data);
                atx.login(data.message.access_token);
                atx.setuserInfo(data);
                ctx.setModalTitle("Verification Successful");
                ctx.setModalMessage(
                  "Your phone number has been successfully verified. We have logged you in."
                );
                ctx.setShowModal(true);
                navigate("/welcome");
              } else {
                if (
                  data &&
                  data.message &&
                  data.message.error === "invalid_grant"
                ) {
                  ctx.setModalTitle("Verification number not correct");
                  ctx.setModalMessage(
                    "Please check your message and try again."
                  );
                  ctx.setShowModal(true);
                } else {
                  ctx.setModalTitle("Verification Unsuccessful");
                  ctx.setModalMessage(
                    "Please check your message and try again."
                  );
                  ctx.setShowModal(true);
                }
              }
            })
            .catch((err) => {
              ctx.setModalTitle("Verification Unsuccessful");
              console.log("err");
              ctx.setModalMessage(err.message);
              ctx.setShowModal(true);
              ctx.setShowAlert(false);
            });
        } else {
          const data = response.json();
          console.log("data");
          console.log(data);
          ctx.setModalTitle("Verification Unsuccessful");
          ctx.setModalMessage(data.content);
          ctx.setShowModal(true);
          ctx.setShowAlert(false);
        }
      })
      .catch((err) => {
        console.log("err");
        console.log(err);
        ctx.setModalTitle("Something went wrong");
        ctx.setModalMessage(err.message);
        ctx.setShowModal(true);
      });

    resetVerificationNumber();
  };

  const titleClasses = titleHasError ? "invalid" : "";

  const firstNameClasses = firstNameHasError ? "invalid" : "";

  const lastNameClasses = lastNameHasError ? "invalid" : "";

  const passwordClasses = passwordHasError ? "invalid" : "";

  const emailClasses = emailHasErrorState ? "invalid" : "pt-3";

  const phoneNumberClasses = phoneNumberHasError
    ? "input-group invalid"
    : "input-group";

  const verificationClasses = verificationNumberHasError
    ? "pt-3 invalid"
    : " pt-3";

  const addStepper = (event) => {
    if (form_step > 5) {
      return;
    } else {
      setFormStep(form_step + 1);
    }
  };

  const subtractStepper = (event) => {
    if (form_step === 0) {
      return;
    } else {
      setFormStep(form_step - 1);
    }
  };

  const formContent = (
    <>
      {form_step === 0 && (
        <form onSubmit={onSubmitHandler}>
          <Stepper
            steps={[
              { title: "Names" },
              { title: "Email" },
              { title: "Password" },
              { title: "Phone" },
              { title: "Verification" },
            ]}
            activeStep={form_step}
            circleFontSize={13}
            size={40}
          />
          <h1 className="pt-4">Create your Masaru Account</h1>
          <div className="pb-3">
            Start with your <b>title</b>, <b>first name</b> and <b>last name</b>
            .
          </div>
          <Row>
            <Col xl="2" xs="12" className={titleClasses}>
              <label htmlFor="title">Title</label>
              <Form.Select
                value={titleValue}
                className="mb-3 form-control"
                id="title"
                onChange={titleChangeHandler}
                onClick={titleBlurHandler}
              >
                <option value="">Title</option>
                <option value="Mr">Mr.</option>
                <option value="Mrs">Mrs.</option>
                <option value="Miss">Miss.</option>
                <option value="Ms">Ms.</option>
                <option value="Dr">Dr.</option>
                <option value="Hon">Hon.</option>
                <option value="Prof">Prof.</option>
              </Form.Select>
              {titleHasError && (
                <p className="error-text">Please enter a title.</p>
              )}
            </Col>
            <Col xl xs="12" className={firstNameClasses}>
              <label htmlFor="firstName">First Name</label>
              <input
                type="text"
                value={firstNameValue}
                className="mb-3 form-control"
                placeholder="First Name"
                id="firstName"
                onChange={firstNameChangeHandler}
                onBlur={firstNameBlurHandler}
              />
              {firstNameHasError && (
                <p className="error-text">Please enter a first name.</p>
              )}
            </Col>
            <Col xl xs="12" className={lastNameClasses}>
              <label htmlFor="lastName">Last Name</label>
              <input
                type="text"
                value={lastNameValue}
                className="mb-3 form-control"
                placeholder="Last Name"
                id="lastName"
                onChange={lastNameChangeHandler}
                onBlur={lastNameBlurHandler}
              />
              {lastNameHasError && (
                <p className="error-text">Please enter a last name.</p>
              )}
            </Col>
          </Row>
          <Row className="float-right">
            {/* Stack the columns on mobile by making one full-width and the other half-width */}
            <Col>
              <div className="d-grid gap-2 pt-3">
                <button className="btn btn-primary float-right" type="submit">
                  Next
                </button>
              </div>
            </Col>
            <Container>
              {/* Stack the columns on mobile by making one full-width and the other half-width */}
              <div className="d-sm-none">
                <Col md={12}>
                  By registering, you accept our Terms of use and Privacy Policy
                </Col>
                <Col className="pt-4 text-center">
                  <NavLink to="/login">Already have an account? Login</NavLink>
                </Col>
              </div>
              <div className="d-none d-sm-block">
                <Row>
                  <Col>
                    By registering, you accept our Terms of use and Privacy
                    Policy
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <NavLink to="/login" className="float-end">
                      Already have an account? Login
                    </NavLink>
                  </Col>
                </Row>
              </div>
            </Container>
          </Row>
        </form>
      )}

      {form_step === 1 && (
        <form onSubmit={onSubmitEmailHandler}>
          <Stepper
            steps={[
              { title: "Names" },
              { title: "Email" },
              { title: "Password" },
              { title: "Phone" },
              { title: "Verification" },
            ]}
            activeStep={form_step}
            circleFontSize={13}
            size={40}
          />
          <Col md={3}>
            <div className="pt-5">
              <span
                className="pt-5 show-pointer text-primary"
                onClick={subtractStepper}
              >
                {"< "} <strong>Back to edit your names</strong>
              </span>
            </div>
          </Col>
          <h1 className="pt-4">Enter your email account</h1>
          <div className={emailClasses}>
            <input
              type="text"
              value={emailValue}
              className="mb-3 form-control"
              placeholder="Type email here"
              id="email"
              onChange={emailChangeHandler}
              onBlur={emailBlurHandler}
            />
            {emailHasErrorState && (
              <p className="error-text">Please enter a valid email address.</p>
            )}
          </div>
          <Row className="float-right">
            {/* Stack the columns on mobile by making one full-width and the other half-width */}
            <Col>
              <div className="d-grid gap-2 pt-3">
                <button className="btn btn-primary float-right" type="submit">
                  Next
                </button>
              </div>
            </Col>
          </Row>
        </form>
      )}

      {form_step === 2 && (
        <form onSubmit={onPasswordSubmitPhoneHandler}>
          <Stepper
            steps={[
              { title: "Names" },
              { title: "Email" },
              { title: "Password" },
              { title: "Phone" },
              { title: "Verification" },
            ]}
            activeStep={form_step}
          />
          <Col md={3}>
            <div className="pt-5">
              <span
                className="pt-5 show-pointer text-primary"
                onClick={subtractStepper}
              >
                {"< "} <strong>Back to edit your email address</strong>
              </span>
            </div>
          </Col>
          <h1 className="pt-4">Create your password</h1>
          <div className="pb-3">
            Must be 8 characters or more and include a capital letter, a small letter, a number, and a
            special character.
          </div>
          <div className={passwordClasses}>
            <input
              type="password"
              value={passwordValue}
              className="mb-3 form-control"
              id="password"
              onChange={passwordChangeHandler}
              onBlur={passwordBlurHandler}
            />
            {passwordHasError && (
              <p className="error-text">Please enter a valid password.</p>
            )}
          </div>
          <Row className="float-right">
            {/* Stack the columns on mobile by making one full-width and the other half-width */}
            <Col>
              <div className="d-grid gap-2 pt-3">
                <button className="btn btn-primary float-right" type="submit">
                  Next
                </button>
              </div>
            </Col>
            <Container>
              {/* Stack the columns on mobile by making one full-width and the other half-width */}
              <div className="d-sm-none">
                <Col md={12}></Col>
                <Col className="pt-4 text-center">
                  <NavLink to="/login">Already have an account? Login</NavLink>
                </Col>
              </div>
              <div className="d-none d-sm-block">
                <Row>
                  <Col>
                    <NavLink to="/login" className="float-end">
                      Already have an account? Login
                    </NavLink>
                  </Col>
                </Row>
              </div>
            </Container>
          </Row>
        </form>
      )}

      {form_step === 3 && (
        <form onSubmit={onSubmitPhoneHandler}>
          <Stepper
            steps={[
              { title: "Names" },
              { title: "Email" },
              { title: "Password" },
              { title: "Phone" },
              { title: "Verification" },
            ]}
            activeStep={form_step}
            circleFontSize={13}
            size={40}
          />
          <Col md={3}>
            <div className="pt-5">
              <span
                className="pt-5 show-pointer text-primary"
                onClick={subtractStepper}
              >
                {"< "} <strong>Back to edit your password</strong>
              </span>
            </div>
          </Col>

          <h1 className="pt-4">Enter your phone number</h1>
          <Col md={7}>
            <div className="form-group pt-4">
              <div className={phoneNumberClasses}>
                <Form.Select
                  value={countryValue}
                  className="form-select"
                  id="country"
                  onChange={countryChangeHandler}
                >
                  <option value="">Choose a country</option>
                  <option value="">------------------------</option>
                  <option value="254">Kenya (+254)</option>
                  <option value="">------------------------</option>
                  <option value="213">Algeria (+213)</option>
                  <option value="376">Andorra (+376)</option>
                  <option value="244">Angola (+244)</option>
                  <option value="1264">Anguilla (+1264)</option>
                  <option value="1268">Antigua &amp; Barbuda (+1268)</option>
                  <option value="54">Argentina (+54)</option>
                  <option value="374">Armenia (+374)</option>
                  <option value="297">Aruba (+297)</option>
                  <option value="61">Australia (+61)</option>
                  <option value="43">Austria (+43)</option>
                  <option value="994">Azerbaijan (+994)</option>
                  <option value="1242">Bahamas (+1242)</option>
                  <option value="973">Bahrain (+973)</option>
                  <option value="880">Bangladesh (+880)</option>
                  <option value="1246">Barbados (+1246)</option>
                  <option value="375">Belarus (+375)</option>
                  <option value="32">Belgium (+32)</option>
                  <option value="501">Belize (+501)</option>
                  <option value="229">Benin (+229)</option>
                  <option value="1441">Bermuda (+1441)</option>
                  <option value="975">Bhutan (+975)</option>
                  <option value="591">Bolivia (+591)</option>
                  <option value="387">Bosnia Herzegovina (+387)</option>
                  <option value="267">Botswana (+267)</option>
                  <option value="55">Brazil (+55)</option>
                  <option value="673">Brunei (+673)</option>
                  <option value="359">Bulgaria (+359)</option>
                  <option value="226">Burkina Faso (+226)</option>
                  <option value="257">Burundi (+257)</option>
                  <option value="855">Cambodia (+855)</option>
                  <option value="237">Cameroon (+237)</option>
                  <option value="1">Canada (+1)</option>
                  <option value="238">Cape Verde Islands (+238)</option>
                  <option value="1345">Cayman Islands (+1345)</option>
                  <option value="236">Central African Republic (+236)</option>
                  <option value="56">Chile (+56)</option>
                  <option value="86">China (+86)</option>
                  <option value="57">Colombia (+57)</option>
                  <option value="269">Comoros (+269)</option>
                  <option value="242">Congo (+242)</option>
                  <option value="682">Cook Islands (+682)</option>
                  <option value="506">Costa Rica (+506)</option>
                  <option value="385">Croatia (+385)</option>
                  <option value="53">Cuba (+53)</option>
                  <option value="90392">Cyprus North (+90392)</option>
                  <option value="357">Cyprus South (+357)</option>
                  <option value="42">Czech Republic (+42)</option>
                  <option value="45">Denmark (+45)</option>
                  <option value="253">Djibouti (+253)</option>
                  <option value="1809">Dominica (+1809)</option>
                  <option value="1809">Dominican Republic (+1809)</option>
                  <option value="593">Ecuador (+593)</option>
                  <option value="20">Egypt (+20)</option>
                  <option value="503">El Salvador (+503)</option>
                  <option value="240">Equatorial Guinea (+240)</option>
                  <option value="291">Eritrea (+291)</option>
                  <option value="372">Estonia (+372)</option>
                  <option value="251">Ethiopia (+251)</option>
                  <option value="500">Falkland Islands (+500)</option>
                  <option value="298">Faroe Islands (+298)</option>
                  <option value="679">Fiji (+679)</option>
                  <option value="358">Finland (+358)</option>
                  <option value="33">France (+33)</option>
                  <option value="594">French Guiana (+594)</option>
                  <option value="689">French Polynesia (+689)</option>
                  <option value="241">Gabon (+241)</option>
                  <option value="220">Gambia (+220)</option>
                  <option value="7880">Georgia (+7880)</option>
                  <option value="49">Germany (+49)</option>
                  <option value="233">Ghana (+233)</option>
                  <option value="350">Gibraltar (+350)</option>
                  <option value="30">Greece (+30)</option>
                  <option value="299">Greenland (+299)</option>
                  <option value="1473">Grenada (+1473)</option>
                  <option value="590">Guadeloupe (+590)</option>
                  <option value="671">Guam (+671)</option>
                  <option value="502">Guatemala (+502)</option>
                  <option value="224">Guinea (+224)</option>
                  <option value="245">Guinea - Bissau (+245)</option>
                  <option value="592">Guyana (+592)</option>
                  <option value="509">Haiti (+509)</option>
                  <option value="504">Honduras (+504)</option>
                  <option value="852">Hong Kong (+852)</option>
                  <option value="36">Hungary (+36)</option>
                  <option value="354">Iceland (+354)</option>
                  <option value="91">India (+91)</option>
                  <option value="62">Indonesia (+62)</option>
                  <option value="98">Iran (+98)</option>
                  <option value="964">Iraq (+964)</option>
                  <option value="353">Ireland (+353)</option>
                  <option value="972">Israel (+972)</option>
                  <option value="39">Italy (+39)</option>
                  <option value="1876">Jamaica (+1876)</option>
                  <option value="81">Japan (+81)</option>
                  <option value="962">Jordan (+962)</option>
                  <option value="7">Kazakhstan (+7)</option>
                  <option value="254">Kenya (+254)</option>
                  <option value="686">Kiribati (+686)</option>
                  <option value="850">Korea North (+850)</option>
                  <option value="82">Korea South (+82)</option>
                  <option value="965">Kuwait (+965)</option>
                  <option value="996">Kyrgyzstan (+996)</option>
                  <option value="856">Laos (+856)</option>
                  <option value="371">Latvia (+371)</option>
                  <option value="961">Lebanon (+961)</option>
                  <option value="266">Lesotho (+266)</option>
                  <option value="231">Liberia (+231)</option>
                  <option value="218">Libya (+218)</option>
                  <option value="417">Liechtenstein (+417)</option>
                  <option value="370">Lithuania (+370)</option>
                  <option value="352">Luxembourg (+352)</option>
                  <option value="853">Macao (+853)</option>
                  <option value="389">Macedonia (+389)</option>
                  <option value="261">Madagascar (+261)</option>
                  <option value="265">Malawi (+265)</option>
                  <option value="60">Malaysia (+60)</option>
                  <option value="960">Maldives (+960)</option>
                  <option value="223">Mali (+223)</option>
                  <option value="356">Malta (+356)</option>
                  <option value="692">Marshall Islands (+692)</option>
                  <option value="596">Martinique (+596)</option>
                  <option value="222">Mauritania (+222)</option>
                  <option value="269">Mayotte (+269)</option>
                  <option value="52">Mexico (+52)</option>
                  <option value="691">Micronesia (+691)</option>
                  <option value="373">Moldova (+373)</option>
                  <option value="377">Monaco (+377)</option>
                  <option value="976">Mongolia (+976)</option>
                  <option value="1664">Montserrat (+1664)</option>
                  <option value="212">Morocco (+212)</option>
                  <option value="258">Mozambique (+258)</option>
                  <option value="95">Myanmar (+95)</option>
                  <option value="264">Namibia (+264)</option>
                  <option value="674">Nauru (+674)</option>
                  <option value="977">Nepal (+977)</option>
                  <option value="31">Netherlands (+31)</option>
                  <option value="687">New Caledonia (+687)</option>
                  <option value="64">New Zealand (+64)</option>
                  <option value="505">Nicaragua (+505)</option>
                  <option value="227">Niger (+227)</option>
                  <option value="234">Nigeria (+234)</option>
                  <option value="683">Niue (+683)</option>
                  <option value="672">Norfolk Islands (+672)</option>
                  <option value="670">Northern Marianas (+670)</option>
                  <option value="47">Norway (+47)</option>
                  <option value="968">Oman (+968)</option>
                  <option value="680">Palau (+680)</option>
                  <option value="507">Panama (+507)</option>
                  <option value="675">Papua New Guinea (+675)</option>
                  <option value="595">Paraguay (+595)</option>
                  <option value="51">Peru (+51)</option>
                  <option value="63">Philippines (+63)</option>
                  <option value="48">Poland (+48)</option>
                  <option value="351">Portugal (+351)</option>
                  <option value="1787">Puerto Rico (+1787)</option>
                  <option value="974">Qatar (+974)</option>
                  <option value="262">Reunion (+262)</option>
                  <option value="40">Romania (+40)</option>
                  <option value="7">Russia (+7)</option>
                  <option value="250">Rwanda (+250)</option>
                  <option value="378">San Marino (+378)</option>
                  <option value="239">Sao Tome &amp; Principe (+239)</option>
                  <option value="966">Saudi Arabia (+966)</option>
                  <option value="221">Senegal (+221)</option>
                  <option value="381">Serbia (+381)</option>
                  <option value="248">Seychelles (+248)</option>
                  <option value="232">Sierra Leone (+232)</option>
                  <option value="65">Singapore (+65)</option>
                  <option value="421">Slovak Republic (+421)</option>
                  <option value="386">Slovenia (+386)</option>
                  <option value="677">Solomon Islands (+677)</option>
                  <option value="252">Somalia (+252)</option>
                  <option value="27">South Africa (+27)</option>
                  <option value="34">Spain (+34)</option>
                  <option value="94">Sri Lanka (+94)</option>
                  <option value="290">St. Helena (+290)</option>
                  <option value="1869">St. Kitts (+1869)</option>
                  <option value="1758">St. Lucia (+1758)</option>
                  <option value="249">Sudan (+249)</option>
                  <option value="597">Suriname (+597)</option>
                  <option value="268">Swaziland (+268)</option>
                  <option value="46">Sweden (+46)</option>
                  <option value="41">Switzerland (+41)</option>
                  <option value="963">Syria (+963)</option>
                  <option value="886">Taiwan (+886)</option>
                  <option value="7">Tajikstan (+7)</option>
                  <option value="66">Thailand (+66)</option>
                  <option value="228">Togo (+228)</option>
                  <option value="676">Tonga (+676)</option>
                  <option value="1868">Trinidad &amp; Tobago (+1868)</option>
                  <option value="216">Tunisia (+216)</option>
                  <option value="90">Turkey (+90)</option>
                  <option value="7">Turkmenistan (+7)</option>
                  <option value="993">Turkmenistan (+993)</option>
                  <option value="1649">
                    Turks &amp; Caicos Islands (+1649)
                  </option>
                  <option value="688">Tuvalu (+688)</option>
                  <option value="256">Uganda (+256)</option>
                  <option value="44">UK (+44)</option>
                  <option value="380">Ukraine (+380)</option>
                  <option value="971">United Arab Emirates (+971)</option>
                  <option value="598">Uruguay (+598)</option>
                  <option value="1">USA (+1)</option>
                  <option value="7">Uzbekistan (+7)</option>
                  <option value="678">Vanuatu (+678)</option>
                  <option value="379">Vatican City (+379)</option>
                  <option value="58">Venezuela (+58)</option>
                  <option value="84">Vietnam (+84)</option>
                  <option value="84">Virgin Islands - British (+1284)</option>
                  <option value="84">Virgin Islands - US (+1340)</option>
                  <option value="681">Wallis &amp; Futuna (+681)</option>
                  <option value="969">Yemen (North)(+969)</option>
                  <option value="967">Yemen (South)(+967)</option>
                  <option value="260">Zambia (+260)</option>
                  <option value="263">Zimbabwe (+263)</option>
                </Form.Select>
                <input
                  type="text"
                  value={phoneNumberValue}
                  className="form-control w-25"
                  id="email"
                  placeholder=""
                  onChange={phoneNumberChangeHandler}
                  onBlur={phoneNumberBlurHandler}
                />
              </div>
              {phoneNumberHasError && (
                <p className="error-text">
                  Please enter a valid country code and phone number.
                </p>
              )}
            </div>
          </Col>

          <Row className="float-right pt-4">
            {/* Stack the columns on mobile by making one full-width and the other half-width */}
            <Col>
              <div className="d-grid gap-2">
                <button className="btn btn-primary float-right" type="submit">
                  Send Verification Code
                </button>
              </div>
            </Col>
          </Row>
        </form>
      )}

      {form_step === 4 && (
        <form onSubmit={onSubmitVerificationHandler}>
          <Stepper
            steps={[
              { title: "Names" },
              { title: "Email" },
              { title: "Password" },
              { title: "Phone" },
              { title: "Verification" },
            ]}
            activeStep={form_step}
            circleFontSize={13}
            size={40}
          />
          <Col md={3}>
            <div className="pt-5">
              <span
                className="pt-5 show-pointer text-primary"
                onClick={subtractStepper}
              >
                {"< "} <strong>Back to edit your phone number</strong>
              </span>
            </div>
          </Col>
          <h1 className="pt-4">Verify Phone number</h1>
          <div className="pb-3">
            An SMS with a verification number has been sent to{" +"}
            {countryValue}
            {phoneNumberValue}.
          </div>
          <div className={verificationClasses}>
            <label htmlFor="verification">
              Type your 6-digit security code here
            </label>
            <input
              type="text"
              value={verificationNumberValue}
              className="mb-3 form-control"
              id="verification"
              onChange={verificationNumberChangeHandler}
              onBlur={verificationNumberBlurHandler}
            />
            {verificationNumberHasError && (
              <p className="error-text">
                Please enter a valid verification number from the SMS sent to
                your phone.
              </p>
            )}
          </div>

          <Row className="float-right">
            {/* Stack the columns on mobile by making one full-width and the other half-width */}
            <Col>
              <div className="d-grid gap-2 pt-4">
                <button className="btn btn-primary float-right" type="submit">
                  Next
                </button>
              </div>
            </Col>
            <div className="d-grid gap-2 pt-5">
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={onRequestNewOTP}
              >
                Resend Code to {"+"}
                {countryValue}
                {phoneNumberValue}
              </button>
            </div>
          </Row>
        </form>
      )}
    </>
  );
  return <div>{formContent}</div>;
};

export default RegistrationStepper;
