import { useReducer } from 'react';

const initialInputState = {
  value: '',
  isTouched: false,
  isSubmitted: false,
};

const inputStateReducer = (state, action) => {
  if (action.type === 'INPUT') {
    return { value: action.value, isTouched: state.isTouched };
  }
  if (action.type === 'BLUR') {
    return { isTouched: true, value: state.value };
  }
  if (action.type === 'RESET') {
    return { isTouched: false, value: '', isSubmitted: false };
  }
  if (action.type === 'SUBMIT') {
    return { isSubmitted: true, isTouched: state.isTouched, value: action.value};
  }
  return inputStateReducer;
};

const useInput = (validateValue) => {
  const [inputState, dispatch] = useReducer(
    inputStateReducer,
    initialInputState
  );

  const valueIsValid = validateValue(inputState.value);
  const hasError = !valueIsValid && (inputState.isTouched || inputState.isSubmitted);


  const valueChangeHandler = (event) => {
    dispatch({ type: 'INPUT', value: event.target.value });
  };

  const inputBlurHandler = (event) => {
    dispatch({ type: 'BLUR' });
  };

  const reset = () => {
    dispatch({ type: 'RESET' });
  };

  const submitto = () => {
    dispatch({ type: 'SUBMIT', value: inputState.value });
  };

  return {
    value: inputState.value,
    isValid: valueIsValid,
    hasError,
    valueChangeHandler,
    inputBlurHandler,
    reset,
    submitto
  };
};

export default useInput;