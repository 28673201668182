import { Alert, Container } from "react-bootstrap";

const MyAlert = (props) => {
  
  return (
    <Alert
      variant={props.variant}
      show={props.showAlert}
      onClose={() => props.setShowAlert(false)}
      dismissible
    >
      <Container>
        <div className='p-3'>
          <Alert.Heading>{props.heading}</Alert.Heading>
          {props.message}
        </div>
      </Container>
    </Alert>
  );
};

export default MyAlert;
