import { useState, useContext } from "react";
import { Route, Routes } from "react-router-dom";

import "./App.css";

import MainNavigation from "./components/main-navigation";
import BottomNavigation from "./components/bottom-navigation";

import Login from "./pages/auth/login";
import Forgot from "./pages/auth/forgot";
import RegistrationStepper from "./pages/auth/registration_stepper";
import TermsAndConditions from "./pages/public/terms-and-conditions";
import NotFound from "./pages/public/not-found";
import Welcome from "./pages/user/welcome";
import Test from "./pages/user/test";
import ContactUs from "./pages/public/contact_us";
import Products from "./pages/user/products";
import Profile from "./pages/user/profile";
import Statement from "./pages/user/statement";

import NotificationContext from "./store/notifications-context";

import MyAlert from "./components/alert";
import MyModal from "./components/modal";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Navbar from "react-bootstrap/Navbar";
import { Container } from "react-bootstrap";

import AuthContext from "./store/auth-context";
import { act } from "react-dom/test-utils";



function App() {
  const [showAlert, setShowAlert] = useState(false);
  const [variant, setAlertVariant] = useState("success");
  const [heading, setAlertHeading] = useState("{ none }");
  const [alertMessage, setAlertMessage] = useState("{ message comes here }");

  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("{ none }");
  const [modalMessage, setModalMessage] = useState("{ message comes here }");

  const atx = useContext(AuthContext);

  return (
    
    <NotificationContext.Provider value={{
      showAlert,
      setShowAlert,
      setAlertVariant,
      setAlertHeading,
      setAlertMessage,
      setModalTitle,
      setModalMessage,
      setShowModal
  }}>
      <MainNavigation></MainNavigation>
      <MyAlert
        key="alert"
        variant={variant}
        heading={heading}
        message={alertMessage}
        showAlert={showAlert}
        setShowAlert={setShowAlert}
      />
      <MyModal
        key="modal"
        show={showModal}
        modalTitle={modalTitle}
        modalMessage={modalMessage}
        setShowModal={setShowModal}
      />
      <Container>
        <div className="pt-3 pb-5 mb-5">
          <Routes>
            <Route path="/" element={<Login />}></Route>
            <Route path="/registration" element={<RegistrationStepper />}></Route>
            <Route path="/test" element={<Test />}></Route>
            <Route path="/forgot" element={<Forgot />}></Route>
            <Route path="/terms" element={<TermsAndConditions />}></Route>
            <Route path="/contact_us" element={<ContactUs />}></Route>
            {atx.isLoggedIn && <Route path="/welcome" element={<Welcome />}></Route>}
            {!atx.isLoggedIn && <Route path="/products" element={<Products />}></Route>}
            {atx.isLoggedIn && <Route path="/profile" element={<Profile />}></Route>}
            {!atx.isLoggedIn && <Route path="/statement" element={<Statement />}></Route>}
            <Route path="*" element={<Login />}></Route>
          </Routes>
        </div>
      </Container>
      <BottomNavigation></BottomNavigation>
    </NotificationContext.Provider>
  );
}

export default App;
