import AuthContext from "../../store/auth-context";
import { useContext } from "react";

const Products = () => {
  const atx = useContext(AuthContext);
  console.log(atx.token);
  return <h1>Welcome</h1>;

};

export default Products;
