import AuthContext from "../../store/auth-context";
import React, { useState, useEffect, useContext } from "react";
import NotificationContext from "../../store/notifications-context";

const Test = () => {
  const [areDetailsLoaded, setAreDetailsLoaded] = useState(false);
  const atx = useContext(AuthContext);
  const ctx = useContext(NotificationContext);
  var firstname = '';

  useEffect(() => {
    fetch(`http://${process.env.REACT_APP_SERVER_IP}:${process.env.REACT_APP_SERVER_PORT}/users/get_userinfo`, {
      method: "POST",
      body: JSON.stringify({}),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + atx.token,
      },
    }).then((response) => {
      if (response.ok) {
        response
          .json()
          .then((data) => {
            console.log(data);
            console.log(data.message.given_name)
            firstname = data.message.given_name;
            atx.setuserInfo(data);
          })
          .catch((err) => {
            console.log(err);
            ctx.setAlertVariant("danger");
            ctx.setAlertHeading("Could not submit your information1");
            ctx.setAlertMessage("Correct the errors, then try again.");
            ctx.setShowAlert(true);
          });
      } else {
        ctx.setAlertVariant("danger");
        ctx.setAlertHeading("Could not submit your information2");
        ctx.setAlertMessage("Correct the errors, then try again.");
        ctx.setShowAlert(true);
      }
    });
    setAreDetailsLoaded(true);
  }, []);

  return(
  <>
  <h1>Test</h1>
 {areDetailsLoaded === true && <div>Here</div>};
 {!areDetailsLoaded === true && <div>There</div>};
  </>
  );
};

export default Test;
