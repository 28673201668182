import React, { useContext } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from 'react-bootstrap/Form';

import useInput from "../../hooks/use-input";
import NotificationContext from "../../store/notifications-context";
const isEmail = (value) => value.includes("@");



const ContactUs = () => {
  const ctx = useContext(NotificationContext);

  const {
    value: emailValue,
    isValid: emailIsValid,
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: resetEmail,
    submitto: submitEmail,
  } = useInput(isEmail);

  let formIsValid = false;

  if (emailIsValid) {
    formIsValid = true;
  }

  const onSubmitHandler = (event) => {
    event.preventDefault();

    if (!formIsValid) {
      ctx.setAlertVariant("danger");
      ctx.setAlertHeading("Could not submit your information");
      ctx.setAlertMessage("Correct the errors, then try again.");
      ctx.setShowAlert(true);
      submitEmail();
      return;
    }

    fetch(`http://${process.env.REACT_APP_SERVER_IP}:${process.env.REACT_APP_SERVER_PORT}/users/change_password`, {
      method: "POST",
      body: JSON.stringify({
        email: emailValue,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.ok) {
        response
          .json()
          .then((data) => {
            ctx.setAlertVariant("success");
            ctx.setAlertHeading("Reset Successful");
            ctx.setAlertMessage(
              "A verification email has been sent to your email"
            );
            ctx.setShowAlert(true);
          })
          .catch((err) => {
            ctx.setAlertVariant("danger");
            ctx.setAlertHeading("Could not submit your information");
            ctx.setAlertMessage("Correct the errors, then try again.");
            ctx.setShowAlert(true);
          });
      } else {
        ctx.setAlertVariant("danger");
        ctx.setAlertHeading("Could not submit your information");
        ctx.setAlertMessage("Correct the errors, then try again.");
        ctx.setShowAlert(true);
      }
    });
    resetEmail();
  };

  const emailClasses = emailHasError ? "invalid" : "";

  return (
    <>
    <form onSubmit={onSubmitHandler}>
      <h3>Contact Us</h3>
      <div className="pb-3">
        To reset your password, please provide your <b>email address</b>, your <b>mobile number</b> and your <b>message</b>.
      </div>
      <div className={emailClasses}>
        <label htmlFor="email">Email</label>
        <input
          type="text"
          value={emailValue}
          className="mb-3 form-control"
          placeholder="We may send you an email"
          id="email"
          onChange={emailChangeHandler}
          onBlur={emailBlurHandler}
        />
        {emailHasError && (
          <p className="error-text">Please enter a valid email address.</p>
        )}
      </div>
      <div className={emailClasses}>
        <label htmlFor="phone_number">Phone Number</label>
        <input
          type="text"
          value={emailValue}
          className="mb-3 form-control"
          placeholder="We may need to call you if urgent"
          id="phone_number"
          onChange={emailChangeHandler}
          onBlur={emailBlurHandler}
        />
        {emailHasError && (
          <p className="error-text">Please enter a valid email address.</p>
        )}
      </div>
      <div className={emailClasses}>
        <label htmlFor="message">Message</label>
        <Form.Control as="textarea"
        rows={3}
        value={emailValue}
          className="mb-3 form-control"
          placeholder="Type your message here"
          id="email"
          onChange={emailChangeHandler}
          onBlur={emailBlurHandler}
          />
        {emailHasError && (
          <p className="error-text">Please enter a valid email address.</p>
        )}
      </div>
      <Row className="float-right">
        {/* Stack the columns on mobile by making one full-width and the other half-width */}
        <Col xl={{ span: 2, offset: 10 }} xs="12">
          <div className="d-grid gap-2 pt-3">
            <button className="btn btn-primary float-right" type="submit">
              Send Message
            </button>
          </div>
        </Col>
      </Row>
    </form>
    <Row className="float-right">
        <Col className="text-secondary">
        <h4>Contact Details</h4>
        Landline: +254 20 440 6836 <br/> <br/>
        info[at]synesis.co.ke <br/> <br/>
        3rd Floor, Panesar's Centre <br/>
        (Opposite Vision Plaza), <br/>
        Mombasa Rd (Suite 308) <br/>
        P.O. Box 75282-00200 <br/>
        Nairobi, Kenya. <br/>
        </Col>
    </Row>
    </>
  );
};

export default ContactUs;
