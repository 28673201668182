import { useEffect } from "react";

const ResetNotifications = (ctx) => {
    const setShowAlert = ctx.setShowAlert;
    const setShowModal = ctx.setShowModal;
    useEffect(() => {
      setShowAlert(false);
      setShowModal(false);
    }, [setShowAlert, setShowModal]);
};

export default ResetNotifications;