import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";


const MyModal = (props) => {
  const handleClose = () => props.setShowModal(false);
  
  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{props.modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.modalMessage}</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MyModal;
